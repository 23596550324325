import styled from "@emotion/styled";
import {
  AuthProvider,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import shallow from "zustand/shallow";
import FadingDivider from "../../components/divider/fading";
import LoadingBackdrop from "../../components/loading-backdrop";
import {
  AnonymousSignInButton,
  EmailSignInButton,
  GoogleSignInButton,
  SignInTextField,
} from "../../components/sign-in-button";
import { track } from "../../libs/analytics";
import { checkIfIsNewUser } from "../../libs/auth/utils";
import { posterUsingCustomToken } from "../../libs/fetcher";
import firebaseApp from "../../libs/firebase/app";
import { reportError } from "../../libs/sentry/logger";
import useSnackbarStore from "../../state/snackbar-store";
import useUserStore from "../../state/user-store";
import { PostMergeResponse } from "../../types/api";

interface Props {
  onSignIn?: (success: boolean) => void;
  displayAnonymousAuth?: boolean;
  displayText?: boolean;
  redirectPath?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignInText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.system};
  font-size: ${(props) => props.theme.fontSize.base};
  margin: 0 0 1rem 0;
`;

const buttonMarginStyle: React.CSSProperties = { marginBottom: "1rem" };

const IS_SIGNING_IN_KEY = "is-signing-in";

const CLOSED_BY_USER_CODE = "auth/popup-closed-by-user";

const getIsSigningIn = () => {
  if (window) {
    const isSigningIn = sessionStorage.getItem(IS_SIGNING_IN_KEY);
    return isSigningIn && isSigningIn === "true";
  }
  return false;
};

const SignInContainer: React.FC<Props> = React.memo(
  ({
    onSignIn,
    displayAnonymousAuth,
    displayText = true,
    redirectPath = "/",
  }) => {
    const [isSigningIn, setIsSigningIn] = useState(getIsSigningIn());
    const [isUsingEmail, setIsUsingEmail] = useState(false);
    const [user, setDisplayGuide] = useUserStore(
      (state) => [state.user, state.setDisplayGuide],
      shallow
    );

    const pushSnackbar = useSnackbarStore(
      (state) => state.pushSnackbar,
      shallow
    );

    useEffect(() => {
      track("SignIn Container Opened", {
        display_anonymous_auth: displayAnonymousAuth || false,
        display_text: displayText,
      });
    }, []);

    const auth = getAuth(firebaseApp);

    const googleProvider = new GoogleAuthProvider();

    const onSignInWithPopup = useCallback((provider: AuthProvider) => {
      setIsSigningIn(true);

      track("Login Opened", {
        provider_id: provider.providerId,
      });

      signInWithPopup(auth, provider)
        .then((user) => {
          const isNewUser = checkIfIsNewUser(user.user);
          track("Login Completed", {
            is_anonymous: false,
            is_new_user: isNewUser,
            provider_id: user.user.providerId,
            operation_type: user.operationType,
          });
          if (isNewUser) {
            track("New User Registered", {
              is_anonymous: false,
              provider_id: user.user.providerId,
              operation_type: user.operationType,
            });
          }
          setDisplayGuide(isNewUser);
          setIsSigningIn(false);
          if (onSignIn) onSignIn(true);
        })
        .catch((err) => {
          if (err.code !== CLOSED_BY_USER_CODE) {
            reportError(err);
            pushSnackbar({
              message: "Kunde inte logga in 😔",
              isCloseable: true,
            });
          }
          track("Login Cancelled", {
            closed_by_user: err.code === CLOSED_BY_USER_CODE,
          });
          setIsSigningIn(false);
          if (onSignIn) onSignIn(false);
        });
    }, []);

    const onLinkWithPopup = useCallback(
      (provider: AuthProvider) => {
        setIsSigningIn(true);

        track("User Merge Opened", {
          provider_id: provider.providerId,
        });

        const anonymousUser = auth.currentUser;
        if (!anonymousUser) return;

        signInWithPopup(auth, provider)
          .then((resp) => {
            // Signed in with provider => let's merge anonymous user with the one signed in
            anonymousUser.getIdToken().then((idToken) => {
              posterUsingCustomToken<PostMergeResponse>(
                "/api/users/merge",
                idToken,
                {
                  body: JSON.stringify({
                    oldUser: anonymousUser.uid,
                    newUser: resp.user?.uid,
                  }),
                }
              ).catch((err) => {
                reportError(err);
              });
            });

            if (resp != null) {
              const cred = GoogleAuthProvider.credentialFromResult(resp);
              if (cred != null) {
                linkWithCredential(anonymousUser, cred)
                  .then((linkResp) => {
                    const cred =
                      GoogleAuthProvider.credentialFromResult(linkResp);
                    if (cred != null) {
                      signInWithCredential(auth, cred);
                    }
                  })
                  .catch((e) => {
                    reportError(e);
                    if (cred != null) {
                      signInWithCredential(auth, cred);
                    }
                  })
                  .finally(() => {
                    track("User Merge Completed");
                    anonymousUser.delete();
                    setIsSigningIn(false);
                    if (onSignIn) onSignIn(true);
                  });
              }
            }
          })
          .catch((err) => {
            if (err.code !== CLOSED_BY_USER_CODE) {
              reportError(err);
              pushSnackbar({
                message: "Kunde inte logga in 😔",
                isCloseable: true,
              });
            }
            track("User Merge Cancelled", {
              closed_by_user: err.code === CLOSED_BY_USER_CODE,
            });

            setIsSigningIn(false);
            if (onSignIn) onSignIn(false);
          });
      },
      [user]
    );

    const onSignInAnonymously = useCallback(() => {
      setIsSigningIn(true);

      track("Login Opened", {
        provider_id: "anonymous",
      });

      signInAnonymously(auth)
        .then((user) => {
          const isNewUser = checkIfIsNewUser(user.user);
          track("Login Completed", {
            is_anonymous: true,
            is_new_user: isNewUser,
            provider_id: user.providerId,
            operation_type: user.operationType,
          });
          if (isNewUser) {
            track("New User Registered", {
              is_anonymous: true,
              provider_id: user.providerId,
              operation_type: user.operationType,
            });
          }
          setDisplayGuide(isNewUser);
          setIsSigningIn(false);
          if (onSignIn) onSignIn(true);
        })
        .catch((err) => {
          reportError(err);
          pushSnackbar({
            message: "Något gick fel 😔",
            isCloseable: true,
          });

          track("Login Cancelled", {
            closed_by_user: err.code === CLOSED_BY_USER_CODE,
          });
          setIsSigningIn(false);
          if (onSignIn) onSignIn(false);
        });
    }, []);

    const emailValidator = useCallback(
      (email: string) =>
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        ),
      []
    );

    const displaySignInEmailTextField = useCallback(() => {
      track("Login Email Field Opened");
      setIsUsingEmail(true);
    }, []);

    const onSendSignInWithEmailLink = useCallback((email: string) => {
      track("Login Email Link Sending", { email, redirect_path: redirectPath });

      setIsSigningIn(true);

      sendSignInLinkToEmail(auth, email, {
        url: `${window.location.origin}/login?redirect=${redirectPath}`,
        handleCodeInApp: true,
      })
        .then(() => {
          localStorage.setItem("emailForSignIn", email);
          track("Login Email Link Sent", {
            email,
            redirect_path: redirectPath,
          });
          pushSnackbar({
            message:
              "Vi har skickat ett mail till dig med en länk där du kan logga in.\nKommer inget mail? Kolla gärna din skräppost!",
            isCloseable: true,
          });
        })
        .catch((e) => {
          reportError(e);
        })
        .finally(() => {
          setIsSigningIn(false);
          setIsUsingEmail(false);
        });
    }, []);

    return (
      <Container>
        {displayText && (
          <SignInText>Synka dina spel med alla dina enheter:</SignInText>
        )}
        {isUsingEmail ? (
          <SignInTextField
            label="Email"
            type="email"
            autoComplete="email"
            validator={emailValidator}
            onSignIn={onSendSignInWithEmailLink}
          />
        ) : (
          <EmailSignInButton
            id="email-sign-in-button"
            style={buttonMarginStyle}
            onClick={displaySignInEmailTextField}
          />
        )}

        <GoogleSignInButton
          id="google-sign-in-button"
          style={buttonMarginStyle}
          onClick={() =>
            user?.isAnonymous
              ? onLinkWithPopup(googleProvider)
              : onSignInWithPopup(googleProvider)
          }
        />
        {displayAnonymousAuth && (
          <>
            <FadingDivider />
            <AnonymousSignInButton
              id="anonymous-sign-in-button"
              style={buttonMarginStyle}
              onClick={onSignInAnonymously}
            />
          </>
        )}
        {isSigningIn && <LoadingBackdrop open={isSigningIn} />}
      </Container>
    );
  }
);

export default SignInContainer;
