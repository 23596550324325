import styled from "@emotion/styled";
import Avatar from "@material-ui/core/Avatar";
import { ButtonProps } from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import ForwardIcon from "@material-ui/icons/Forward";
import MailIcon from "@material-ui/icons/Mail";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { getContrast } from "polished";
import React, { useCallback, useRef, useState } from "react";
import { theme } from "../../libs/styled";
import OutlinedButton from "../button/outlined";

interface IconProps {
  icon?: React.ReactNode;
  imageSrc?: string;
  iconText?: string;
}

interface StyledButtonProps {
  backgroundColor: string;
}

interface Props extends IconProps, StyledButtonProps, ButtonProps {}

const ICON_SIZE = 1.25;

const IconImg = styled.img`
  width: ${ICON_SIZE}rem;
  height: ${ICON_SIZE}rem;
`;

const getTextColor = (backgroundColor: string) => {
  const { white, black } = theme.color;
  const blackContrast = getContrast(backgroundColor, black);
  const whiteContrast = getContrast(backgroundColor, white);
  if (whiteContrast > blackContrast) return white;
  return black;
};

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})<StyledButtonProps>`
  width: ${ICON_SIZE}rem;
  height: ${ICON_SIZE}rem;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => getTextColor(props.backgroundColor)};
`;

const StyledButton = styled(OutlinedButton)`
  justify-content: start;
`;

const EndIconButton = styled(IconButton)`
  padding: 6px;
  margin-right: -0.5rem;
`;

const StyledTextField = styled(TextField)<TextFieldProps>`
  margin-bottom: 1rem;
  margin-top: 0;

  .MuiOutlinedInput-root {
    border-radius: 1rem;

    font-family: ${(props) => props.theme.fontFamily.system};
    font-size: ${(props) => props.theme.fontSize.base};
    letter-spacing: ${(props) => props.theme.letterSpacing.none};
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 10px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
  .MuiFormLabel-root {
    font-family: ${(props) => props.theme.fontFamily.system};
    font-size: ${(props) => props.theme.fontSize.base};
    letter-spacing: ${(props) => props.theme.letterSpacing.none};

    .Mui-focused {
      color: ${(props) => props.theme.color.blue.default};
    }
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.color.blue.default};
  }
`;

const getStartIcon = ({ icon, imageSrc, iconText, backgroundColor }: Props) => {
  if (icon) return icon;
  if (imageSrc) return <IconImg src={imageSrc} alt="Start icon" />;
  if (iconText)
    return (
      <StyledAvatar backgroundColor={backgroundColor}>
        {iconText.toUpperCase()}
      </StyledAvatar>
    );
  return null;
};

const SignInButton: React.FC<Props> = React.memo((props) => {
  const {
    children,
    backgroundColor,
    icon,
    imageSrc,
    iconText,
    ...rest
  } = props;
  return (
    <StyledButton
      trackingName="Sign In"
      startIcon={getStartIcon({ icon, imageSrc, iconText, backgroundColor })}
      {...rest}
    >
      {children}
    </StyledButton>
  );
});

export default SignInButton;

const GOOGLE_LOGO_URL =
  "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg";

export const EmailSignInButton: React.FC<ButtonProps> = (props) => (
  <SignInButton backgroundColor="#fff" icon={<MailIcon />} {...props}>
    Logga in med mail
  </SignInButton>
);

export const GoogleSignInButton: React.FC<ButtonProps> = (props) => (
  <SignInButton backgroundColor="#fff" imageSrc={GOOGLE_LOGO_URL} {...props}>
    Logga in med Google
  </SignInButton>
);

export const AnonymousSignInButton: React.FC<ButtonProps> = (props) => (
  <SignInButton
    backgroundColor={theme.color.orange.default}
    icon={<PermIdentityIcon />}
    {...props}
  >
    Fortsätt som utloggad
  </SignInButton>
);

interface SignInTextFieldProps {
  onSignIn?: (text: string) => void;
  validator?: (text: string) => boolean;
}

export const SignInTextField: React.FC<
  SignInTextFieldProps & TextFieldProps
> = (props) => {
  const [text, setText] = useState<string | undefined>();
  const [error, setError] = useState(false);
  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (error) setError(false);
      setText(event.target.value);
    },
    [setText, error]
  );
  const onSignIn = useCallback(() => {
    inputRef.current?.blur();
    if (!props.validator?.(text || "")) {
      setError(true);
    } else {
      props.onSignIn?.(text ? text.trim() : "");
    }
  }, [text, setError]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onSignIn();
      }
    },
    [onSignIn]
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <StyledTextField
      {...props}
      inputRef={inputRef}
      error={error}
      variant="outlined"
      margin="dense"
      value={text}
      onKeyDown={handleKeyDown}
      onChange={handleTextChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EndIconButton
              onClick={onSignIn}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              <ForwardIcon />
            </EndIconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
