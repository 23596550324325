import styled from "@emotion/styled";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const StyledBackdrop = styled(Backdrop)`
  z-index: 10000;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.color.white};
`;

interface Props {
  open: boolean;
}

const LoadingBackdrop: React.FC<Props> = React.memo(({ open }) => {
  return (
    <StyledBackdrop open={open}>
      <StyledCircularProgress size="4rem" />
    </StyledBackdrop>
  );
});

export default LoadingBackdrop;
